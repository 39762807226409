import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import BlogBase from "./blogBase"
import useLabels from "../components/useLabels"

export default function Category({ data, pageContext }) {
  const { category } = useLabels(pageContext.lang)
  const seoData = {
    meta_title: `${category} - ${get(data, "prismicCategory.data.category")}`,
  }
  return (
    <BlogBase
      title={`${category} - ${get(data, "prismicCategory.data.category")}`}
      data={data}
      seo={seoData}
      type="category"
      slugUID={pageContext.category}
      pageContext={pageContext}
    />
  )
}

export const query = graphql`
  query($lang: String, $limit: Int, $skip: Int, $category: String) {
    prismicCategory(uid: { eq: $category }) {
      data {
        category
      }
    }
    posts: allPrismicPost(
      filter: {
        lang: { eq: $lang }
        data: {
          categories: { elemMatch: { category: { uid: { eq: $category } } } }
        }
      }
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        uid
        data {
          featured_image {
            alt
            url
            dimensions {
              width
              height
            }
            fluid(maxWidth: 400, imgixParams: { maxW: 400 }) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
          date(formatString: "Do MMMM, yyyy", locale: $lang)
          title {
            text
          }
          text {
            excerpt
          }
        }
      }
    }
    allTags: allPrismicTag(filter: { lang: { eq: $lang } }) {
      nodes {
        uid
        data {
          tag
        }
      }
    }
    usedTags: allPrismicPost(filter: { lang: { eq: $lang } }) {
      group(field: data___tags___tag___uid) {
        fieldValue
      }
    }
    allCategories: allPrismicCategory(filter: { lang: { eq: $lang } }) {
      nodes {
        uid
        data {
          category
        }
      }
    }
    postsPerCategory: allPrismicPost(filter: { lang: { eq: $lang } }) {
      group(field: data___categories___category___uid) {
        totalCount
        fieldValue
      }
    }
    recentPosts: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          featured_image {
            alt
            url
            dimensions {
              width
              height
            }
            fluid(maxWidth: 100, imgixParams: { maxW: 100 }) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
          date(formatString: "Do MMMM, yyyy", locale: $lang)
        }
      }
    }
    config: prismicConfiguration(lang: { eq: $lang }) {
      data {
        linkedin_link
        facebook_link
        twitter_link
      }
    }
  }
`
